import payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Uxd9pAi64dXrtEmZmcm2PJdUFKRce2RhtTdNRng1Ubg from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_I3c_jmtakkZvHEGhtos_bB3bGNFC8kYCtEZUnNU2FFE from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4VONvgj5e2TRgw5K3v4PX263gq2_vYWgewviRkB3ztM from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_o4eg0KkAxBkZJSY70WtAsJbQvhqOjRvVJNn1bBg3ILk from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tsqEt5YLhFM4t08zboyvQMyNt7Y61a1PlpEN9GIMxBQ from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UB9_ofuHAuZtvp0PLwMaUcPRUm3CZQ9qp8gA_R5u4dU from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_8WvyGDbnYRJt4o_ZN9cQCwyCaNKeGU8zLx5HhIyitSM from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_ndenMhuHpEb1SJeI3T9D075JsVgJfuArk7LhVucygko from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.13.16_db0@0.3.1_eslint@9.23.0_jiti@2.4.2__io_lpovywbbgfmptmfacb3vus3xdu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CRDTDxgx_pAgYzElf_i9yspDa7VqxVtnhA7d62Uy73w from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import switch_locale_path_ssr_o0tunxvYgtkZ_rniqpraeAu4gKWSs22WMG3vqjfgWuw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_kPZiu5t37WxV9hrlElTF8BRy3io9HhsgppGCLuEM5CU from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_olN9U9w58EtSfjVnmP5F214841AKWqne_1Bpwa_UjWY from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_eeWtiv_GpXmHLPPjyvRAK59cWop3DPpT1F_8XjmGp3k from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_tqvcoghzevnzczatfgkdcnnlqm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0 from "/vercel/path0/plugins/floating-vue.ts";
import router_options_AI5c2vxQtmMPDivMHf8CGIAo_e9pQXFKL7Qwq2dx_rs from "/vercel/path0/plugins/router.options.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/plugins/sentry.client.ts";
import v_select_k3NA6oNqf1NbNQSWMQDxwG6GLK1ua4SaZC5xPViWuOo from "/vercel/path0/plugins/v-select.ts";
import vue_cookie_comply_with_reject_client_BY_5oyB9TpLgSxPZnJvXfZtUBnB_4QMREwxUhqNZiiA from "/vercel/path0/plugins/vue-cookie-comply-with-reject.client.ts";
import ssg_detect_5q2wA_8rlMfFQRTUZLPuilXcKnulSek1qzlYTjLiDDw from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@9.4.0_@vue+compiler-dom@3.5.13_eslint@9.23.0_jiti@2.4.2__magicast@0.3.5_rollup@4_bnqeruzqo63du75hu5aqnbzium/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_w0HDFr_j2IsnaU6rXjh462d6_UAIdSXuZM71S4bTz44,
  revive_payload_client_Uxd9pAi64dXrtEmZmcm2PJdUFKRce2RhtTdNRng1Ubg,
  unhead_I3c_jmtakkZvHEGhtos_bB3bGNFC8kYCtEZUnNU2FFE,
  router_4VONvgj5e2TRgw5K3v4PX263gq2_vYWgewviRkB3ztM,
  payload_client_o4eg0KkAxBkZJSY70WtAsJbQvhqOjRvVJNn1bBg3ILk,
  navigation_repaint_client_tsqEt5YLhFM4t08zboyvQMyNt7Y61a1PlpEN9GIMxBQ,
  check_outdated_build_client_UB9_ofuHAuZtvp0PLwMaUcPRUm3CZQ9qp8gA_R5u4dU,
  chunk_reload_client_8WvyGDbnYRJt4o_ZN9cQCwyCaNKeGU8zLx5HhIyitSM,
  plugin_vue3_VOBLJeFFdlw9Z7wNERmNnNdg4S5xCSiHp3LAaOiknDo,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_ndenMhuHpEb1SJeI3T9D075JsVgJfuArk7LhVucygko,
  plugin_CRDTDxgx_pAgYzElf_i9yspDa7VqxVtnhA7d62Uy73w,
  switch_locale_path_ssr_o0tunxvYgtkZ_rniqpraeAu4gKWSs22WMG3vqjfgWuw,
  route_locale_detect_kPZiu5t37WxV9hrlElTF8BRy3io9HhsgppGCLuEM5CU,
  i18n_olN9U9w58EtSfjVnmP5F214841AKWqne_1Bpwa_UjWY,
  plugin_eeWtiv_GpXmHLPPjyvRAK59cWop3DPpT1F_8XjmGp3k,
  floating_vue_IzZvwu4d0V2PmLzX5FM8OeN_n9KoM824GlcNgFB4ru0,
  router_options_AI5c2vxQtmMPDivMHf8CGIAo_e9pQXFKL7Qwq2dx_rs,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE,
  v_select_k3NA6oNqf1NbNQSWMQDxwG6GLK1ua4SaZC5xPViWuOo,
  vue_cookie_comply_with_reject_client_BY_5oyB9TpLgSxPZnJvXfZtUBnB_4QMREwxUhqNZiiA,
  ssg_detect_5q2wA_8rlMfFQRTUZLPuilXcKnulSek1qzlYTjLiDDw
]