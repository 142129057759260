import type { RouteLocationNormalized } from '#vue-router'

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized, from: RouteLocationNormalized): Promise<void> => {
  if (import.meta.client) {
    const { $i18n: i18n } = useNuxtApp()

    // Set locale from query parameter if it's different from the current locale
    if (to.query.locale !== i18n.locale.value && i18n.availableLocales.includes(to.query.locale as 'keys' | 'en' | 'de' | 'it')) {
      await i18n.setLocale(to.query.locale as 'keys' | 'en' | 'de' | 'it')
    }
  }
})
