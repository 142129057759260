
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as errorN_45ENTyPlpzPg6hn_0BxpsMhhH7pcBYCZkt9W5qJyQFYMeta } from "/vercel/path0/pages/error.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as partnereLLswL1A66N_VBJH7xOlH7dUdONjd5GbyHJPLNmt9Z8Meta } from "/vercel/path0/pages/partner.vue?macro=true";
import { default as paymentEomlGmSsGmwx1wJiYAkLgXxa7adGDdkhVChH_ZjCZKgMeta } from "/vercel/path0/pages/payment.vue?macro=true";
import { default as successNhuXZrifDFrJuYmZhJLbJXAfeRoouILUxT6r8EhW6BwMeta } from "/vercel/path0/pages/success.vue?macro=true";
import { default as time_45upYuzdCAei9y1M7VyqH71UMFY8_tel1mB_45lJG1DSJRjU8Meta } from "/vercel/path0/pages/time-up.vue?macro=true";
import { default as maintenanceS2D_452B_kVL2s2_45Fnro7E5I4H1bMe8HzzSXmiOWH5i_UMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as registration2l_7dXZ9prFg_45f4bJLIrZ9_8dAlZOEMuwuZg4eGCIlkMeta } from "/vercel/path0/pages/registration.vue?macro=true";
import { default as sentry_45test_45page_45tbIIOjdccvbjAOMdw6cYtC7_45DPKqX56wEWoFwZ0mssMeta } from "/vercel/path0/pages/sentry-test-page.vue?macro=true";
import { default as no_45active_45pricelistdOLxFb1YZ0MJqI0DzW8nkEV5UK_45vELGIavgdMXzMqAQMeta } from "/vercel/path0/pages/no-active-pricelist.vue?macro=true";
export default [
  {
    name: "error",
    path: "/error",
    meta: errorN_45ENTyPlpzPg6hn_0BxpsMhhH7pcBYCZkt9W5qJyQFYMeta || {},
    component: () => import("/vercel/path0/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "partner",
    path: "/partner",
    meta: partnereLLswL1A66N_VBJH7xOlH7dUdONjd5GbyHJPLNmt9Z8Meta || {},
    component: () => import("/vercel/path0/pages/partner.vue")
  },
  {
    name: "payment",
    path: "/payment",
    meta: paymentEomlGmSsGmwx1wJiYAkLgXxa7adGDdkhVChH_ZjCZKgMeta || {},
    component: () => import("/vercel/path0/pages/payment.vue")
  },
  {
    name: "success",
    path: "/success",
    meta: successNhuXZrifDFrJuYmZhJLbJXAfeRoouILUxT6r8EhW6BwMeta || {},
    component: () => import("/vercel/path0/pages/success.vue")
  },
  {
    name: "time-up",
    path: "/time-up",
    meta: time_45upYuzdCAei9y1M7VyqH71UMFY8_tel1mB_45lJG1DSJRjU8Meta || {},
    component: () => import("/vercel/path0/pages/time-up.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue")
  },
  {
    name: "registration",
    path: "/registration",
    meta: registration2l_7dXZ9prFg_45f4bJLIrZ9_8dAlZOEMuwuZg4eGCIlkMeta || {},
    component: () => import("/vercel/path0/pages/registration.vue")
  },
  {
    name: "sentry-test-page",
    path: "/sentry-test-page",
    component: () => import("/vercel/path0/pages/sentry-test-page.vue")
  },
  {
    name: "no-active-pricelist",
    path: "/no-active-pricelist",
    meta: no_45active_45pricelistdOLxFb1YZ0MJqI0DzW8nkEV5UK_45vELGIavgdMXzMqAQMeta || {},
    component: () => import("/vercel/path0/pages/no-active-pricelist.vue")
  }
]